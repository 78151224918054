import React, { useState, useRef, useEffect } from 'react';
import './css/PhotoMaskEditor.css'

const PhotoMaskEditor = ({ image, onPhotoMaskSave }) => {
  const [isDrawing, setIsDrawing] = useState(false);
  const [prevX, setPrevX] = useState(0);
  const [prevY, setPrevY] = useState(0);
  const [brushColor, setBrushColor] = useState('#000000'); // Default brush color
  const [brushSize, setBrushSize] = useState(5); // Default brush size
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.lineJoin = 'round';
    ctx.lineCap = 'round';
    ctx.strokeStyle = brushColor;
    ctx.lineWidth = brushSize;
  }, [brushColor, brushSize]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    if (image) {
      const img = new Image();
      img.onload = () => {
        canvas.width = img.width; // Set canvas width to image width
        canvas.height = img.height; // Set canvas height to image height
        ctx.drawImage(img, 0, 0); // Draw image on canvas
      };
      img.src = image;
    }
  }, [image]);

  const startDrawing = (event) => {
    setIsDrawing(true);
    setPrevX(event.nativeEvent.offsetX);
    setPrevY(event.nativeEvent.offsetY);
  };

  const draw = (event) => {
    if (!isDrawing) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
  
    ctx.beginPath();
    ctx.moveTo(prevX, prevY);
    ctx.lineTo(event.nativeEvent.offsetX, event.nativeEvent.offsetY);
    ctx.stroke();
  
    // Set drawn area on canvas to fully transparent
    ctx.globalCompositeOperation = 'destination-out'; // Set to "destination-out" mode for erasing
    ctx.lineWidth = brushSize; // Set the line width to match the brush size
  
    // Calculate the distance between the previous and current positions
    const deltaX = event.nativeEvent.offsetX - prevX;
    const deltaY = event.nativeEvent.offsetY - prevY;
    const distance = Math.sqrt(deltaX ** 2 + deltaY ** 2);
    const angle = Math.atan2(deltaY, deltaX);
  
    // Draw a series of small circles along the path to erase
    for (let i = 0; i < distance; i += brushSize / 4) {
      const x = prevX + Math.cos(angle) * i;
      const y = prevY + Math.sin(angle) * i;
      ctx.beginPath();
      ctx.arc(x, y, brushSize / 2, 0, Math.PI * 2);
      ctx.fill();
    }
  
    ctx.globalCompositeOperation = 'source-over'; // Reset to default mode
  
    setPrevX(event.nativeEvent.offsetX);
    setPrevY(event.nativeEvent.offsetY);
  };
  
  
  const endDrawing = () => {
    setIsDrawing(false);
    const canvas = canvasRef.current;
    canvas.toBlob((blob) => {
      const file = new File([blob], 'photo_mask.png', { type: 'image/png' }); // Create File object with PNG MIME type
      onPhotoMaskSave(file); // Pass the File object to the parent component
    });
  };

  const downloadMask = () => {
    const canvas = canvasRef.current;
    const link = document.createElement('a');
    link.download = 'photo_mask.png';
    link.href = canvas.toDataURL();
    link.click();
  };

  return (
    <div className="mask-editor">
      <div className='mask-subtitle'>
        <p>Please draw over the area to be changed</p>
      </div>
      <div className='mask-editor-container'>
        
        <div className="toolbar">
            {/* <p>Create Photo Mask</p> */}
            {/* <label htmlFor="brushColor">Brush Color:</label>
            <input
            type="color"
            id="brushColor"
            name="brushColor"
            value={brushColor}
            onChange={(e) => setBrushColor(e.target.value)}
            /> */}
            <label htmlFor="brushSize">Brush Size:</label>
            <input
            type="range"
            id="brushSize"
            name="brushSize"
            value={brushSize}
            min="1"
            max="200"
            onChange={(e) => setBrushSize(parseInt(e.target.value))}
            />
            {/* <span>{brushSize}</span> */}
        </div>
        <div className="canvas-container">
            <div className='mask-download-button-container'>
                <button className='mask-download-button' onClick={downloadMask}>Download</button>
            </div>
            <canvas
            ref={canvasRef}
            onMouseDown={startDrawing}
            onMouseMove={draw}
            onMouseUp={endDrawing}
            onMouseOut={endDrawing}
            style={{ border: '1px solid black' }}
            />
        </div>
      </div>
    </div>
  );
};

export default PhotoMaskEditor;
