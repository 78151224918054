import React from 'react';

function EmailPopup({ onClose, message }) {
  return (
    <div className="modal">
      <div className="modal-content">
        <p className="message">{message}</p>
        <button className="close-button" onClick={onClose}>Close</button>
      </div>
    </div>
  );
}

export default EmailPopup;