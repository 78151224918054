// import * as React from 'react';
import {API_ROOT} from '../../Constants.js';

const PostEmail = async (params) => {
  // console.log("Attempting Signup with params", params);
  try {
    // const response = await fetch(VARS.apiPath+'users/sign_up', {
    // const response = await fetch(VARS.apiPath+'users', {
    const response = await fetch(API_ROOT+'/api/v1/sign_up', {
      method: 'POST',
      headers: { 
        // Accept: 'application/json', 
        'Content-Type': 'application/json', 
        // 'X-User-Email': localStorage.getItem('email'),
        // 'X-User-Token': localStorage.getItem('token')
      },
      body: params,
    });
    // if (response) {
    //   console.log("has response");
    // } else{ 
    //   console.log("no response");
    // }
    const resp = await response.json();
    // const userToken = await response.headers.get('authorization');
    return resp;
  } catch (error){
    console.log("ErrorRegistration", error);
  }
};
export default PostEmail;