// import React, { useState, useRef } from 'react';
import React, { useState, useEffect, useRef } from 'react';

import './css/CreateImageEdit.css';
import './css/AddressToImagesEdit.css';
import Post from '../../api/v1/Post';
import Get from '../../api/v1/Get';
import PhotoMaskEditor from './PhotoMaskEditor';
import AddressImageDropdown from './AddressImageDropdown'; // Adjust the path as per your project structure


const AddressToImagesEdit = ({ onImageGeneratedSuccess }) => {
  const [address, setAddress] = useState('');
  const [foundImages, setFoundImages] = useState([]);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [textPromptValue, setTextPromptValue] = useState('');
  const [photoMask, setPhotoMask] = useState(null);
  const [uploadedImageFile, setUploadedImageFile] = useState(null);
  const [selectedSize, setSelectedSize] = useState('256x256');
  const [addressImages, getAddressImages] = useState([]);


  useEffect(() => {
    // It is equivalent to componentDidMount and componentDidUpdate in class components
    var session = localStorage.getItem('session_id');
    var auth = `Bearer ${String(session)}`
    Get('addressimgs', auth)
      .then((result) => { 
        getAddressImages(result[1])
        return console.log("from users address images: ", result);
      })
    
    // Cleanup function example
    return () => {  // This code runs before the component is removed from the DOM
      };
  }, [0]); // Dependency array (optional), if empty, the effect runs only once after initial render

    
  const fileInputRef = useRef(null);

  const handleSubmitAddress = async (event) => {
    event.preventDefault();
    try {
      var payloadJson = JSON.stringify({query: address})
      var session = localStorage.getItem('session_id');
      var headerParams = {
        'Accept': '*/*',
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Connection': 'keep-alive',
        'Authorization': `Bearer ${String(session)}`
      }
      Post('pages', payloadJson, headerParams)
      .then((result) => {
        return console.log("from search post: ", result);
      });
      // .then((result) => {
      //   Get('address', getParams) })
      // .then((result) => { 
      //   getAddressImages(result)
      //   return console.log("from users address images: ", result);
      // })
      // Assuming fetchImages is a function that fetches images from the API
      // const response = await fetchImages(address);
      // const data = await response.json();
      // setFoundImages(data.images);
      console.log("get image date from provider")
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  const handleAddressChange = (event) => {
    console.log("what is it?: ",event.target.value);
    setAddress(event.target.value);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setUploadedImage(URL.createObjectURL(file));
    const pngFile = new File([file], 'uploaded_image.png', { type: 'image/png' });
    setUploadedImageFile(pngFile);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setUploadedImage(URL.createObjectURL(file));
    const pngFile = new File([file], 'uploaded_image.png', { type: 'image/png' });
    setUploadedImageFile(pngFile);
  };

  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  const handleTextPromptChange = (event) => {
    setTextPromptValue(event.target.value);
  };

  const handleSizeButtonClick = (size) => {
    setSelectedSize(size);
  };

  const handleGetAddressImage = () => {
    console.log("handle generate image: ", address);
    const formData = new FormData();
    // formData.append('image', uploadedImageFile);
    // formData.append('mask', photoMask);
    formData.append('query', address);
    // formData.append('size', selectedSize);

    var session = localStorage.getItem('session_id');
    var headerParams = {
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Connection: 'keep-alive',
      Authorization: `Bearer ${String(session)}`,
    };
    Post('pages', formData, headerParams).then((result) => {
      // console.log("this is the result: ", result);
      onImageGeneratedSuccess();
    });
  }

  const handleGenerateImage = () => {
    const formData = new FormData();
    formData.append('image', uploadedImageFile);
    formData.append('mask', photoMask);
    formData.append('prompt', textPromptValue);
    formData.append('size', selectedSize);

    var session = localStorage.getItem('session_id');
    var headerParams = {
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Connection: 'keep-alive',
      Authorization: `Bearer ${String(session)}`,
    };
    Post('openai/gen_image_edit', formData, headerParams).then((result) => {
      // console.log("this is the result: ", result);
      onImageGeneratedSuccess();
    });
  };

  return (
    <div>
      {uploadedImage && (
        <PhotoMaskEditor image={uploadedImage} onPhotoMaskSave={setPhotoMask} />
      )}
      <div className="image-upload-container">
        <div className='address-search-bar'>
          <form className='address-search-bar-form' 
          onSubmit={handleSubmitAddress}
          >
            <label className='address-search-bar-label' >
              <input className='address-search-bar-input' type="text" value={address} onChange={handleAddressChange} placeholder='Enter an Address' />
            </label>
            <button 
              // onClick={() => handleGetAddressImage(address)}
              type="submit">Search
            </button>
          </form>
          <div>
            {foundImages.map((image, index) => (
              <img key={index} src={image.url} alt={`Image ${index}`} />
            ))}
          </div>
        </div>
        <div className="size-container">
          <h4>Size: </h4>
          <div className="size-buttons-container">
            {['256x256', '512x512', '1024x1024'].map((size) => (
              <button
                key={size}
                className={`size-button ${selectedSize === size ? 'selected' : ''}`}
                onClick={() => handleSizeButtonClick(size)}
              >
                {size}
              </button>
            ))}
          </div>
        </div>
        <div className='dropdown-parent-container'>
          {/* <p>Locations</p> */}
          <AddressImageDropdown addressImages={addressImages} />
          {/* <div className="image-gallery">
            {addressImages.map((image, index) => (
              <div key={index} className="gallery-item">
                <p>{image[0].location}</p>
                <img src={image[0].url} alt={`Image ${index + 1}`} />
              </div>
            ))}
          </div> */}
          <p class='addy-gen-blurb'>Generate different views of a location or upload images</p>
        </div>
        <div class='addy-gen-alternate-views-button'>
          <button className='generate-image-via-text-button' 
          // onClick={handleGenerateImage}
            >Creates
          </button>
        </div>
        {!uploadedImage && (
        <div
          className="image-upload"
          onDragOver={(event) => event.preventDefault()}
          onDrop={handleDrop}
          onClick={handleBrowseClick}
        >
          <div className="drag-drop-area">
            <p>Click or drag and drop your landscape photo here</p>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              style={{ display: 'none' }}
              ref={fileInputRef}
            />
          </div>
          <button className="upload-button">Upload Landscape Photo</button>
        </div>
        )}
        <div className="create-photos-text">
          <textarea
            value={textPromptValue}
            onChange={handleTextPromptChange}
            placeholder="Enter text here"
            className="text-input create-photos-textarea"
          />
          <button className="generate-image-via-text-button" 
          // onClick={handleGenerateImage}
          >
            Generate Image
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddressToImagesEdit;
