// AuthModal.js
import React, { useState, useEffect } from 'react';

import Post from '../../api/v1/Post'
import PostSignIn from '../../api/v1/ruby/PostSignIn'

import './css/AuthModal.css'; // Import the existing CSS file for authentication modal styling
import './css/PasswordResetModal.css'; // Import the new CSS file for password reset modal styling




const AuthModal = ({ closeModal }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPasswordResetModal, setShowPasswordResetModal] = useState(false);
  const [loginError, setLoginError] = useState(false);


  useEffect(() => {
    // This block will run once when the component mounts
    var session = localStorage.getItem('session_id');
    if (session !== null) {
      // The variable exists in localStorage
      // console.log('Variable exists: ', session);
      console.log('')
      // after confirming session exists, get user details (existing photos, credits available, paid status)

    } else {
      // The variable does not exist in localStorage
      console.log('Variable does not exist');
    }
    return () => {
    };
  }, [1]); 

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  // Implement logic for user authentication
  const handleLogin = () => {
    var sign_in_json = JSON.stringify({'email': email, 'password': password})
    PostSignIn('sign_in', sign_in_json)
    .then((response) => {
      if (response[2]) { // has error message
        console.log("response error: ", response[2]);
        setLoginError(true);
        console.log("show error message");
      } else {  // sign in success, set session and user id to localstorage 
        if (response[1].has('x-session-token')) {
          console.log("response status: ",response[0]);
          localStorage.setItem('session_id', response[1].get('x-session-token'));
          localStorage.setItem('user_id', response[0].user_id);
          setLoginError(false);
          closeModal();
        } else {
          console.log("no login token"); // create new server error
          setLoginError(true);
        }
      }
    })
  };

  const handlePasswordReset = () => {
    setShowPasswordResetModal(true);
  };

  const closePasswordResetModal = () => {
    setShowPasswordResetModal(false);
  };

  return (
    <div className="auth-modal-overlay">
      <div className="auth-modal">
        <h2>Login</h2>
        {loginError && <p className="error-message">Login failed. Please try again.</p>}
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={handleEmailChange}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={handlePasswordChange}
        />
        <button onClick={handleLogin}>Login</button>
        <p onClick={handlePasswordReset} className="password-reset-link">
          Reset your Password?
        </p>
      </div>

      {showPasswordResetModal && (
        <div className="password-reset-modal">
          <div className="password-reset-content">
            <h2>Reset Your Password</h2>
            <p>Enter your email address below to receive a password reset link.</p>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={handleEmailChange}
            />
            <button onClick={closePasswordResetModal}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AuthModal;
