import React, { useState } from 'react';
import './css/CreateImageFromTextPromptFake.css';
// import Post from '../../api/v1/Post';

const CreateImageFromTextPromptFake = ({ onImageGeneratedSuccess }) => {
  const [textPromptValue, setTextPromptValue] = useState('');
  // const [selectedSize, setSelectedSize] = useState('256x256');

  const handleTextPromptChange = (event) => {
    setTextPromptValue(event.target.value);
  };

  // const handleSizeButtonClick = (size) => {
  //   setSelectedSize(size);
  //   console.log("size has been set to: ", size, selectedSize);
  // };

  // const handleGenerateImage = () => {
  //   const formData = new FormData();
  //   formData.append('prompt', textPromptValue);
  //   formData.append('size', selectedSize);

  //   const session = localStorage.getItem('session_id');
  //   // 'Content-Type': 'application/json',
  //   const headerParams = {
  //     Accept: '*/*',
  //     'Cache-Control': 'no-cache',
  //     Connection: 'keep-alive',
  //     Authorization: `Bearer ${String(session)}`,
  //   };
  //   console.log("form to submit: ", formData)
  //   Post('openai', formData, headerParams).then((result) => {
  //     return onImageGeneratedSuccess();
  //   });
  // };

  return (
    <div className="image-upload-container-fake">
      {/* <div className="size-container">
        <h4>Size: </h4>
        <div className="size-buttons-container">
          {['256x256', '512x512', '1024x1024'].map((size) => (
            <button
              key={size}
              className={`size-button ${selectedSize === size ? 'selected' : ''}`}
              // onClick={() => handleSizeButtonClick(size)}
            >
              {size}
            </button>
          ))}
        </div>
      </div> */}
      
      {/* <div className="text-prompt-and-button-container" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}> */}
        <textarea
          value={textPromptValue}
          onChange={handleTextPromptChange}
          placeholder="Create your account to start making images"
          className="text-input-fake"
          // style={{
          //   width: '100%',
          //   resize: 'none',
          //   borderRadius: '5px',
          //   padding: '10px',
          //   margin: 'auto',
          //   flex: 1, // Ensure the textarea takes up all available vertical space
          // }}
        />
        <button
          style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '4px'}}
          className="generate-image-via-text-button-fake"
          // onClick={handleGenerateImage}
        >
          Generate Image
        </button>
      {/* </div> */}
    </div>
  );
};

export default CreateImageFromTextPromptFake;
