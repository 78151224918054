import React, { useState } from 'react';
import './css/CreateImageFromTextPrompt.css';
import Post from '../../api/v1/Post';

const CreateImageFromTextPrompt = ({ onImageGeneratedSuccess, onImageGenerationFailure, onGenerateImage }) => {
  const [textPromptValue, setTextPromptValue] = useState('');
  const [selectedSize, setSelectedSize] = useState('256x256');

  const handleTextPromptChange = (event) => {
    setTextPromptValue(event.target.value);
  };

  const handleSizeButtonClick = (size) => {
    setSelectedSize(size);
  };

  const handleGenerateImage = async () => {
    onGenerateImage();
    const formData = new FormData();
    formData.append('prompt', textPromptValue);
    formData.append('size', selectedSize);

    const session = localStorage.getItem('session_id');
    const headerParams = {
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Connection: 'keep-alive',
      Authorization: `Bearer ${String(session)}`,
    };

    try {
      const [statusCode, responseData] = await Post('openai', formData, headerParams);

      if (statusCode >= 200 && statusCode < 300) {
        onImageGeneratedSuccess(responseData);
      } else {
        // console.log("response status and data: ",statusCode, ": and: ", responseData);
        onImageGenerationFailure(statusCode, responseData);
      }
    } catch (error) {
      // console.log("response status and data: ",error);
      onImageGenerationFailure('network_failure', error);
    }
  };

  return (
    <div className="image-upload-container">
      <div className="size-container">
        <h4>Size: </h4>
        <div className="size-buttons-container">
          {['256x256', '512x512', '1024x1024'].map((size) => (
            <button
              key={size}
              className={`size-button ${selectedSize === size ? 'selected' : ''}`}
              onClick={() => handleSizeButtonClick(size)}
            >
              {size}
            </button>
          ))}
        </div>
      </div>
      
      <div className="create-photos-text">
        <textarea
          value={textPromptValue}
          onChange={handleTextPromptChange}
          placeholder="Enter text here"
          className="text-input"
          style={{
            width: '90%',
            height: '80px',
            resize: 'none',
            borderRadius: '5px',
            padding: '10px',
            margin: 'auto',
          }}
        />
        <button
          className="generate-image-via-text-button"
          onClick={handleGenerateImage}
        >
          Generate Image
        </button>
      </div>
    </div>
  );
};

export default CreateImageFromTextPrompt;
