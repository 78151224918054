import { API_ROOT } from '../../Constants.js';

const Post = async (path, payload, headerParams) => {
  try {
    // console.log("POST PATH: ", API_ROOT + '/api/v1/' + path);
    if (!headerParams) {
      headerParams = {
        'Accept': '*/*',
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Connection': 'keep-alive'
      };
    }
    // console.log("header params: ", headerParams);
    // console.log("payload params: ", payload);

    const response = await fetch(API_ROOT + '/api/v1/' + path, {
      method: 'POST',
      headers: headerParams,
      body: payload,
    });
    // console.log("sent fetch: ", response);

    if (!response.ok) { // handles non-200 response codes
      const errorResponse = await response.json(); // Extract error message from response
      // throw new Error(`HTTP error! Status: ${response.status}, Message: ${errorResponse.message}`);
      return [response.status, errorResponse.message]
    }

    const responseJson = await response.json();
    // console.log("Get Response: ", responseJson);
    // console.log("Get HEADERS: ", responseJson.headers);
    const statusCode = response.status;
    return [statusCode, responseJson];

  } catch (error) { // network issues, server unreachable, cors errors, other
    console.log("Error", error);
    return ['Network Failure', error]
  }
};

export default Post;
