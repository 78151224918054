import React, { useState, useEffect } from 'react';
import './MainFeed.css';
import ImageUpload from './ImageUpload.js'; // Import the ImageUpload component
// import ImageUpload from './ImageUpload.js'; // Import the ImageUpload component
import CreateImageFromTextPromptFake from './screens/subScreens/CreateImageFromTextPromptFake'
// libraries
import { Buffer } from "buffer";
import Rusha from 'rusha';

// API Calls
import Get from './api/v1/Get';
import B2ListFiles from './api/v1/backblaze/B2ListFiles';


// page to show ai generated images of landscapes

const MainFeed = () => {
  const [fileObjects, getFileObjects] = useState([]);
  const [images, setImages] = useState([]);

  const [landscapeDesigns, setLandscapeDesigns] = useState([]);
  // var bbUrl = 'https://api000.backblazeb2.com'

  useEffect(() => {
    Get('proxyimgs')
    .then((result) => {
      // console.log("proxyimg index result: ", result[1]);
      return setImages(result[1]);
    })
    // .then((result) => {d
    //   return console.log("these are the images: ", images);
    // })
    // console.log("these are the images: ", images);
  //   // fetch all images in storage to show in feed. 
  //   Get('backblaze/auth')
  //   .then((result) => {
  //     var bbToken = result;
  //     console.log("show backblaze auth result: ", bbToken);
  //     console.log("show backblaze token: ", bbToken[1]['data']);

  //     // return Promise.resolve(Get('https://api000.backblazeb2.com'+'/b2api/v2/list_file_names', bbToken[1]['data']))
  //     return Promise.resolve(B2ListFiles(bbToken[1]['data'])) })
  //   .then((result) => {
  //     console.log("listed files: ", result)
  //     return Promise.resolve(getFileObjects(result))
  //   })

    // Fetch AI-generated landscape designs or generate sample data
    // For demonstration purposes, I'm using placeholder data
    const generateSampleData = () => {
      const sampleData = [];
      for (let i = 0; i < 10; i++) {
        sampleData.push({
          id: i,
          image: `https://via.placeholder.com/300x200?text=Landscape${i}`,
          name: `Landscape ${i}`,
        });
      }
      return sampleData;
    };

    setLandscapeDesigns(generateSampleData());
  }, [1]);

  return (
    <div className="main-feed">
      <div className="landscape-design">
        <div className="text-box">
          {/* Text box content */}
          <h3>Share your thoughts</h3>
          <textarea placeholder="What would you like to improve?" />
          <button>Post</button>
        </div>
      </div>
      {images.slice(0, 2).map((design, index) => (
        <div key={design.id} className="landscape-design">
          <img src={design.image_variant_url} />
        </div>
      ))}
      <div className="landscape-design">
          {/* <div className="image-upload-wrapper"> */}
            {/* <ImageUpload /> */}
            <CreateImageFromTextPromptFake />
          {/* </div> */}
        
        {/* <ImageUpload /> */}
      </div>
      {images.slice(2, 4).map((design, index) => (
        <div key={design.id} className="landscape-design">
          <img src={design.image_variant_url} />
        </div>
      ))}
      {images.slice(4).map((design, index) => (
        <div key={design.id} className="landscape-design">
          <img src={design.image_variant_url} />
        </div>
      ))}
    </div>
  );
};
// alt={design.name}
export default MainFeed;
