import React, { useState, useRef, useEffect } from 'react';

// libraries
import { Buffer } from "buffer";
import Rusha from 'rusha';

// API Calls
import Get from '../../api/v1/Get';
import Post from '../../api/v1/Post';
// import B2UploadFile from '../../api/v1/backblaze/B2UploadFile';

function DragUpload() {
  const [image, setImage] = useState(null);
  const [fileInfo, setFileInfo] = useState({ type: '', size: '', dimensions: '' });
  const [alterationDescription, setAlterationDescription] = useState('');
  const [conversionText, setConversionText] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const fileInputRef = useRef(null);

  const formatFileSize = (bytes) => {
    const kb = bytes / 1024;
    if (kb < 1024) {
      return kb.toFixed(1) + ' KB';
    } else {
      const mb = kb / 1024;
      return mb.toFixed(1) + ' MB';
    }
  };
  // console.log("image object: ", image);
  const handleUploadClick = () => {
    // upload to backblaze and store link to image as ruby object
    // call upload from ruby, then use output to upload to backblaze, then call create from ruby, using output from backblaze item path
    
    // Convert the image data from Base64 to binary
    // console.log("Image: ",image) //jpeg format
    // console.log("Image: ",selectedImage) //jpeg format

    // Decode the Base64 data
    const base64Data = image.replace(/^data:image\/\w+;base64,/, '');
    const binaryData = Buffer.from(base64Data, 'base64');
    // console.log("decoded image: " , binaryData)
    // console.log("binary data length: ", binaryData.length)

    // Calculate the SHA-1 hash
    const sha1Hash = Rusha.createHash().update(binaryData).digest('hex'); 
    // console.log("SHA-1:", sha1Hash);

    const formData = new FormData()
    formData.append('proxyimg[image]', selectedImage)
    // console.log("show formData class: ",formData.)

    var headers = "Accept: 'application/json', 'Content-Type': 'multipart/form-data', Authorization: props"

    Post('proxyimgs', formData, headers)
    .then((result) => {
      console.log("create proxyimg rails response: ",result)
    });
    // Get('proxyimgs/upload') 
    // .then((result) => {
    //   console.log("upload response: ",result)
    //   console.log("response data: ",result[1])
    //   console.log("response Data: ",result[1]['data'])
    //   return result[1]['data'];  })
    // .then((result) => {
    //   console.log("upload url result: ",result);
    //   return Promise.resolve(B2UploadFile(result, binaryData, sha1Hash)) })
    //   // return Promise.resolve(B2UploadFile(result, binaryImage, digest)) })
    // .then((result) => {
    //     console.log("B2 upload file result: ",result);
    //     // console.log("B2 upload file result: ",result[0]);
    //     console.log("file ID: ",result['fileId']);
    //     // return true; });
    //     var imgReference = JSON.stringify({'fileId':result['fileId']});
    //     return Promise.resolve(Post('proxyimgs', imgReference)) })
    // .then((result) => {
    //   return console.log("ProxyImg create result:", result );
    // });


    // Make an API call to upload the image
    // You can use the 'image' state for the image data
  };

  const handleConvertClick = () => {
    // Make an API call to perform an alteration
    // You can use the 'image' state for the image data
    // You can also use the 'conversionText' state for the text input
  };

  useEffect(() => {
    let description = '';
    if (image) {
      const img = new Image();
      img.src = image;
      img.onload = () => {
        const originalSize = Math.min(img.naturalWidth, img.naturalHeight);
        const canvas = document.createElement('canvas');
        canvas.width = originalSize;
        canvas.height = originalSize;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, originalSize, originalSize);
        const croppedImage = canvas.toDataURL('image/jpeg', 0.9); // Convert to JPEG

        // Check file size and resolution
        const fileSize = croppedImage.length * 0.75; // Calculate approximate file size in bytes
        if (fileSize > 4 * 1024 * 1024) {
          // File size > 4MB, scale down the resolution
          const scale = Math.sqrt((4 * 1024 * 1024) / fileSize);
          canvas.width = originalSize * scale;
          canvas.height = originalSize * scale;
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          const scaledImage = canvas.toDataURL('image/jpeg', 0.9); // Convert to JPEG
          setImage(scaledImage);

          description += `Image was scaled down from ${originalSize}x${originalSize} to ${canvas.width}x${canvas.height}. `;
        }

        const newSize = Math.min(canvas.width, canvas.height);

        setFileInfo({
          type: image.split(';')[0].split('/')[1].toUpperCase(), // Extract image type
          size: formatFileSize(fileSize),
          dimensions: `${newSize}x${newSize}`,
        });

        if (originalSize !== newSize) {
          description += `Image dimensions changed from ${originalSize}x${originalSize} to ${newSize}x${newSize}. `;
        }

        setAlterationDescription(description.trim());
      };
    } else {
      setFileInfo({ type: '', size: '', dimensions: '' });
      setAlterationDescription('');
    }
  }, [image]);

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    // console.log("get file type: ", file)
    // console.log(file.class)
    setSelectedImage(file);
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleFileInput = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
    // console.log("input file: ", file);
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleBoxClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div>
      <div
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onClick={handleBoxClick}
        style={{
          // width: '50vw', // Set width to 50% of screen width
          height: '30vw', // Make it a square
          border: '4px dashed #f5f5f5',
          margin: '5px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#f9f9f9',
          cursor: 'pointer',
          borderRadius: '10px',
        }}
      >
        {!image && <div>Drag & Drop or Click to Upload Image</div>}
        {image && (
          <img
            src={image}
            alt="Uploaded"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              width: 'auto',
              height: 'auto',
            }}
          />
        )}
        <button className="upload-button">Upload Landscape Photo</button>
      </div>
      {image && (
        <div style={{ marginTop: '20px' }}>
          <div>
          <table>
            <tbody>
              <tr>
                <td>Type</td>
                <td>{fileInfo.type}</td>
              </tr>
              <tr>
                <td>Size</td>
                <td>{fileInfo.size}</td>
              </tr>
              <tr>
                <td>Dimensions</td>
                <td>{fileInfo.dimensions}</td>
              </tr>
              {alterationDescription && (
                <tr>
                  <td>Alteration</td>
                  <td>{alterationDescription}</td>
                </tr>
              )}
            </tbody>
          </table><button onClick={handleUploadClick}>Upload</button>
          </div>
          <div>
            <textarea
              rows="4"
              cols="50"
              value={conversionText}
              onChange={(e) => setConversionText(e.target.value)}
              placeholder="Enter text for conversion"
            ></textarea>
            <button onClick={handleConvertClick}>Convert</button>
          </div>
        </div>
      )}
      <input
        type="file"
        accept="image/*"
        onChange={handleFileInput}
        style={{ display: 'none' }}
        ref={fileInputRef}
      />
    </div>
  );
}

export default DragUpload;
