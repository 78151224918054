import React, { useState, useEffect } from 'react';
import './css/AddressImagesDropdown.css';

function AddressImageDropdown({ addressImages }) {
  const [selectedAddress, setSelectedAddress] = useState(null);

  // Set initial selected address when component mounts
  useEffect(() => {
    if (addressImages.length > 0 && !selectedAddress) {
      setSelectedAddress(addressImages.flat()[0].location);
    }
  }, [addressImages, selectedAddress]);

  const handleSelectAddress = (address) => {
    setSelectedAddress(address);
  };

  return (
    <div class='dropdown-screen'>
      <select
        className="custom-dropdown"
        value={selectedAddress || ''}
        onChange={(e) => handleSelectAddress(e.target.value)}
      >
        {addressImages.flat().map((address) => (
          <option value={address.location} key={address.id}>
            {address.location}
          </option>
        ))}
      </select>
      {selectedAddress && (
        <div className="selected-image-container">
          {addressImages.flat().find((address) => address.location === selectedAddress) && (
            <img
              className="selected-image"
              src={addressImages.flat().find((address) => address.location === selectedAddress).url}
              alt={selectedAddress}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default AddressImageDropdown;
