import {API_ROOT} from '../../../Constants.js';

// really just post annd return headers
const PostSignIn = async (path, payload, headerParams) => {
  if (!headerParams) { headerParams = {'Accept': 'application/json', 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'} }         
  // console.log("POST PATH: ",API_ROOT + '/api/v1/'+path)
  // console.log("HEADERS: ",API_ROOT + '/api/v1/'+path)

  const PostJson = async(props) => {
    var errorString = '';
    try {
      const response = await fetch(API_ROOT + '/api/v1/'+path, {
        method: 'POST',
        headers: headerParams,
        body: payload,
      });
      
      if (!response.ok) { // Check if the response status is OK (status code 200-299)
        console.log('HTTP error! Status: ', response.status);
        var errorString = response.status;
      }
      const responseJson = await response.json();
      // console.log("Get JSON Response: ", responseJson);

      // const clonedResponse = response.clone();
      // console.log("Get cloned Response: ", clonedResponse);

      const headers = new Headers(response.headers);
      // console.log("Get HEADERS: ", headers);

      // const contentType = response.headers.get('Content-Type');
      // console.log('Content-Type:', contentType);

      // console.log('Test HEADERS:');
      // headers.forEach((value, name) => {
      //   console.log(`${name}: ${value}`);
      // });
      // console.log('Test HEADERS:');
      const hasAuthorizationHeader = headers.has('x-session-token');
      // console.log('Has Authorization Header:', hasAuthorizationHeader);
  
      const statusCode = response.status;
      return Promise.all([responseJson, headers, errorString])
    } catch (error){
      console.log("Error", error);
      errorString = error;
      return Promise.all([0, 0, errorString])
    }
  }
  return PostJson();
  };

export default PostSignIn;

      // body: JSON.stringify({email: 'hello@enviromentai.com', password: '012345678910'}),
