// Utils
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link, useNavigate } from "react-router-dom";
import {DESIGNS} from './js/Constants.js';

// api Calls
import PostUser from './js/api/v1/PostUser';
import PostEmail from './js/api/v1/PostEmail';
import Post from './js/api/v1/Post'
import Get from './js/api/v1/Get'

// Sub Views
import MainFeed from './js/MainFeed'; // Import the MainFeed component
import LandscapeGallery from './LandscapeGallery'; // Import the LandscapeGallery component
import EmailPopup from './EmailPopup'; // Make sure to import the Popup component
import LoginForm from './js/subViews/LoginForm'; // Make sure to import the Popup component

// css
import './styles.css';
import './LandscapeGallery.css'; // Import the CSS file for LandscapeGallery
import { COLORS } from './js/Constants';
import { DARK_THEME_COLORS } from './js/Constants';

function HomePage() {
  const [email, setEmail] = useState('');
  const [showInvalidEmailPopup, setShowInvalidEmailPopup] = useState(false);
  const handleEmailChange = (e) => {  // console.log("setting email: ",e);  // console.log("setting email to: ",email); 
    setEmail(e.target.value);  };

  const [submittedEmail, setSubmittedEmail] = useState('');
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const navigate = useNavigate()

  const handleLoginSuccess = () => {  // Redirect to another page after successful login  // console.log("handle login success");
    navigate('/home')
  };
  const handleSignupSuccess = () => { setShowCreatedUserPopup(true);  }

  const [showCreatedUserPopup, setShowCreatedUserPopup] = useState(false);
  const [selectedDesign, setSelectedDesign] = useState(null);

  const handleCloseInvalidEmailPopup = () => { setShowInvalidEmailPopup(false);   };
  const handleShowCreatedUserPopup = () => {  setShowCreatedUserPopup(false);   }

  // handle design scroll selection for landscape gallery
  const handleSelectDesign = (design) => {    setSelectedDesign(design);  };

  useEffect(() => {   // check for user session, if none, request login
    var session = localStorage.getItem('session_id');
    if (session !== null) {   navigate('/Home')   }
    return () => {  };
  }, [0]); 

  const handleSubmitEmail = (e) => {
    e.preventDefault(); 
    // check email for validity
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(email)) {
      // check if email exists as user in rails
      var emailJson =  JSON.stringify({ user: {email: email} })
      Post('users/email_exists', emailJson)
      .then((result) => {
        if(result) {
          // show password popup
          // console.log("user exists: ",email)
          setShowLoginPopup(true);
        } else {
          // send user creation request to rails, then signup email verification to user from api // console.log("valid email: ", email);
          PostEmail(JSON.stringify({"email": email, "password": '012345678910'}))
          .then((response) => {
            if (response.status) {
              return Promise.resolve(setShowCreatedUserPopup(true));
            }
        })
        }
      })
    } else {
      // Show the popup if the email is invalid
      setShowInvalidEmailPopup(true);
    }
  };




  return (
    <div style={{ backgroundColor: DARK_THEME_COLORS.BACKGROUND_COLOR }} className="homepage">
      <header style={{ backgroundColor: DARK_THEME_COLORS.BACKGROUND_COLOR }} >
        <h1 style={{color: DARK_THEME_COLORS.TEXT_COLOR}}>EnviromentAI</h1>
        <nav>
          <a style={{color: DARK_THEME_COLORS.TEXT_COLOR}} href="#">Home</a>
        </nav>
      </header>
      <main  style={{ backgroundColor: DARK_THEME_COLORS.BACKGROUND_DARK }}>
        <div style={{display: 'flex', width: '100%'}}>
        <div style={{ flex: 1, width:'50%', flexDirection:'column'}}>
          <div style={{ flex: 1}}>
            <h2 style={{color: DARK_THEME_COLORS.TEXT_COLOR}}>Create Beautiful AI landscapes without a camera</h2>
          </div>
          <div style={{ flex: 1}}>
            <h5 style={{color: DARK_THEME_COLORS.TEXT_COLOR}} >Save money and use AI instead of hiring an expensive landscaper. View what your home would look like in countless styles.</h5>
          </div>
        </div>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width:'50%'}}>
            <form style={{flex:1}}>
              <label style={{flex:1}}>
                <input style={{ boxSizing:'border-box', width: '100%',
                    padding: '10px',
                    borderRadius: '5px',
                  }}
                  type="text" name="emailInput" placeholder="type your email..." 
                  value={email}
                  onChange={handleEmailChange} />
              </label>
              <div className="email-button">
                <button className="gradient-button hover-email-button" 
                    onClick={handleSubmitEmail}>Start using EnviromentAI</button>
                {showInvalidEmailPopup && (
                  <EmailPopup message="Your email seems invalid, is there a typo?" onClose={handleCloseInvalidEmailPopup} />
                )}
                {showCreatedUserPopup && (
                  <EmailPopup message="User Account Created. Click the link in your email to verify your account" onClose={handleShowCreatedUserPopup} />
                )}
                {showLoginPopup && (
                  <LoginForm
                    onClose={() => setShowLoginPopup(false)}
                    onLoginSuccess={handleLoginSuccess}
                    onSignupSuccess={handleSignupSuccess}
                    initialEmail={email}
                  />
                )}
              </div>
              <div  style={{textAlign: 'center'}}>
                <p style={{textAlign: 'right', color: DARK_THEME_COLORS.TEXT_COLOR}}>If you already have an account, we'll log you in</p>
              </div>
            </form>
          </div>
        </div>
        
        <p style={{color: DARK_THEME_COLORS.TEXT_COLOR}} >At EnviromentAI, we're passionate about helping you grow healthy and beautiful plants. Our team of experts has experience in gardening and plant care, and we're excited to share our knowledge with you.</p>
        {/* feed of fake ask for comments and fake upload zone called AestheticImageEditor, and test pictures and  */}
        <MainFeed />

        {/* <div> 
          <h1>Landscape Design Options</h1>
          <LandscapeGallery designs={DESIGNS} onSelectDesign={handleSelectDesign} style={{ '--designs-length': DESIGNS.length, '--animation-duration': `${DESIGNS.length * 5}s` }} />
        </div> */}
      </main>
      <footer  style={{ backgroundColor: DARK_THEME_COLORS.BACKGROUND_COLOR }} >
        <p style={{color: DARK_THEME_COLORS.TEXT_COLOR}} >&copy; 2023 EnviromentAI. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default HomePage;