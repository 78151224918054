// Utils
import React, { useState, useEffect } from 'react';
import {useSearchParams, useNavigate} from "react-router-dom";
import ImageUpload from '../ImageUpload.js'; // Import the ImageUpload component

// api Calls
import Get from '../api/v1/Get'


// Sub Views
// import PasswordModal from '../subViews/PasswordModal'; // Import the PasswordModal component
import AuthModal from './subScreens/AuthModal'; // Import the AuthModal component
import DragUpload from './subScreens/DragUpload'; // Import the AuthModal component
import CreateImageEdit from './subScreens/CreateImageEdit.js'; // Import the CreateImage component
import CreateImageFromTextPrompt from './subScreens/CreateImageFromTextPrompt.js'; // Import the image from text prompt component
import ProgressBar from './subScreens/ProgressBar';
import PhotoHistory from './subScreens/PhotoHistory.js';

import BuyCreditsModalDetails from './subScreens/BuyCreditsModalDetails'; // Import the BuyCreditsModal component
import AddressToImagesEdit from './subScreens/AddressToImagesEdit.js'; // Import the BuyCreditsModal component

import GeneratedImageGallery from './subScreens/GeneratedImageGallery'; // import the gennerated image gallery
import PasswordReset from './PasswordReset'; // Import the PasswordReset page

// css
import '../../styles.css';
import './css/home.css';
import { DARK_THEME_COLORS } from '../Constants';


const Home = () => {
  // const [showPasswordModal, setShowPasswordModal] = useState(true); // Manage Password modal state
  const [user, setUser] = useState([]);
  const [privateImages, getPrivateImages] = useState([]);
  const [genImages, getGenImages] = useState([]);
  const [proxyImages, getProxyImages] = useState([]);
  const [showAuthModal, setShowAuthModal] = useState(true);
  const [showPasswordResetPage, setShowPasswordResetPage] = useState(false);
  const [searchParams, getSearchParams] = useSearchParams();
  const [showImageGeneratedFailureModal, setShowImageGeneratedFailureModal] = useState(false);
  const [imageGeneratedFailureModalMessage, setImageGeneratedFailureModalMessage] = useState('');
  const [insufficientCredits, setInsufficientCredits] = useState(false); // Declare insufficientCredits state
  const [progress, setProgress] = useState(0);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [showBuyCreditsModal, setShowBuyCreditsModal] = useState(false); // Add state for controlling the modal
  const [isSubscriptionPopupOpen, setIsSubscriptionPopupOpen] = useState(false);

  const navigate = useNavigate()

  // check for user session, if none, request login
  useEffect(() => {
    var session = localStorage.getItem('session_id');
    // if has session
    if (session !== null) {
      // console.log('Variable exists in localStorage: ', session);
      setShowAuthModal(false)
      // console.log("url requested: ",'userSessions/'+String(session))

      // Get('sessions',session )
      // Get('users/'+localStorage.getItem('user_id'),'Bearer '+String(session))

      Get('userSessions',`Bearer ${String(session)}`)
      .then((result) => {
        if (result[0] < 300) {
          // console.log("this is get user from userSession result: ", result)
          localStorage.setItem('user_id',JSON.stringify(result[1].data.user_id));
          localStorage.setItem('email',JSON.stringify(result[1].data.email));
          localStorage.setItem('verified',JSON.stringify(result[1].data.verified));
          localStorage.setItem('credits',JSON.stringify(result[1].data.credits));
          localStorage.setItem('user',JSON.stringify(result[1].data));

          return setUser(result[1].data)
        } // else { console.log("bad response to get user: ",result[0])}
      })  // after confirming session exists, get user details (existing photos, has credits status)
      .then((result) => {
        return Get('generatedimgs',`Bearer ${String(session)}`)
      }).then((result) => {
        if (result[0] < 300) { // if there are images
          console.log("generated images: ",result);
          // offset setting an array in an array, so reduce the array
          return getGenImages(result[1])
        } // else bad response
      }).then((result) => {
        // console.log("proxyimg index result: ", result[1]);
        return Get('proxyimgs');
      }).then((result) => {
        // console.log("proxyimg index result: ", result[1]);
        return getProxyImages(result[1]);
      });



      // if does not have session, check for session id as url parameter
    } else {
        // url search param is the stripe checkout id
        // use stripe checkout id to get user from checkout, autologin and get session, then get/set payment
        var checkoutSession = searchParams.get("session_id")
        // var clientReferenceId = searchParams.get("session_id")
        if (checkoutSession)  {
          localStorage.clear();
          Get('/checkouts/'+checkoutSession)
          .then((result) => {
            // console.log("checkout session: ",result);
            // set localstorage session_id to client reference id
            var clientReferenceId = result.data.client_reference_id
            if (clientReferenceId) {
              // console.log("setting user session");
              return localStorage.setItem("session_id", clientReferenceId)
            } 
          }).then((result) => {
            // return Get('userSessions/'+String(session))
            return Get('userSessions',`Bearer ${String(session)}`)
          }).then((result) => {
            if (result[0] < 300) {
              // console.log("this is get user from session result: ", result)
              // set both user and localstorage stuff here
              localStorage.setItem("user_id", JSON.stringify(result[1].user_id))
              localStorage.setItem('email',JSON.stringify(result[1].email));
              localStorage.setItem('verified',JSON.stringify(result[1].verified));
              localStorage.setItem("credits", JSON.stringify(result[1].credits))
              localStorage.setItem('user',JSON.stringify(result[1]));
              return setUser(result[1])
            } 
            // else { 
              // console.log("bad response to get user: ",result[0])
            // }
          });
        } else {
          // boot home, does not have user session nor checkout session
          localStorage.clear();
          navigate('/')
        }
    }
    return () => {
    };
  }, [0]); 

  const openPasswordResetPage = () => { setShowPasswordResetPage(true); };
  const closeModal = () => {  setShowAuthModal(false);  };

  // sign out logic here
  const handleSignOut = () => {
    localStorage.clear();
    navigate('/');
  };

  const handleImageGeneratedSuccess = () => {
    // console.log("handle image generated success"); 
    // get all or new images from user
    
    var session = localStorage.getItem('session_id');
    Get('generatedimgs',`Bearer ${String(session)}`)
    .then((result) => {
      // console.log("get user images result: ",result);
      // store user images in state variable
      if (result[0] < 300) {
        // setProgress(100);
        let interval = setInterval(() => {
          setProgress((prevProgress) => {
            if (prevProgress >= 100) {
              clearInterval(interval);
              setShowProgressBar(false); // Hide progress bar after reaching 100%
              return 0; // Reset progress to 0
            }
            return prevProgress + 1; // Increment progress to 100%
          });
        }, 20); // Fast climb to 100%
        return getGenImages(result[1])          // gen images will be displayed on this home page
      } else {
        // console.log("bad result for get images: ",result)
        // return setProgress(100);
        let interval = setInterval(() => {
          setProgress((prevProgress) => {
            if (prevProgress >= 100) {
              clearInterval(interval);
              setShowProgressBar(false); // Hide progress bar after reaching 100%
              return 0; // Reset progress to 0
            }
            return prevProgress + 1; // Increment progress to 100%
          });
        }, 20); // Fast climb to 100%
      }
    }).then((result) => {
      return setShowProgressBar(false);
    })
    // console.log('Image generated successfully!');

  };
  // console.log("progress: ", progress);
  const handleImageGenerationFailure = (statusCode, errorData) => {
    let message = '';
    let insufficientCredits = false;
    switch (statusCode) {
      case 403:
        message = 'No credits available. Purchase more below.';
        setInsufficientCredits(true); // Set insufficientCredits state to true
        break;
      case 422:
        message = 'Network failure. Please try again later.';
        break;
      case 'network_failure':
        message = 'Network failure. Please try again later.';
        break;
      default:
        message = 'An unknown error occurred. Please try again later.';
    }
    setImageGeneratedFailureModalMessage(`${message}`);
    setShowImageGeneratedFailureModal(true)
    console.log('Image generation failed!');
    setShowProgressBar(false);
    setProgress(0);
  };

  const onGenerateImage = () => { // sets the progress bar
    setShowProgressBar(true); 
    let interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 80) {
          clearInterval(interval);
          // handleImageGeneratedSuccess(); // Trigger success after reaching 80%
          return 80;
        }
        return prevProgress + 1; // Increment progress to 80%
      });
    }, 100); // Slow climb to 80%
  };

  // Function to open the buy credits modal
  const openBuyCreditsModal = () => {
    setShowBuyCreditsModal(true);
    setIsSubscriptionPopupOpen(true);
  };

  // Function to close the buy credits modal
  const closeBuyCreditsModal = () => {
    setShowBuyCreditsModal(false);
  };

  const handleSubscribe = (selectedPlan) => {
  };

  const [createMode, setCreateMode] = useState('text');

  const handleCreateModeTextToImage = () => {
    setCreateMode('text');
  };

  const handleCreateModeImageToImage = () => {
    setCreateMode('image');
  };

  const handleCreateModeZillowToImages = () => {
    setCreateMode('zillow');
  };
          {/* <div><DragUpload /></div> */}

  return (
    <div style={{ backgroundColor: DARK_THEME_COLORS.BACKGROUND_COLOR }} className="home">
      {showAuthModal && <AuthModal closeModal={closeModal} />}
      <header style={{ backgroundColor: DARK_THEME_COLORS.BACKGROUND_COLOR }}>
        <h1 style={{color: DARK_THEME_COLORS.TEXT_COLOR}}>EnviromentAI</h1>
        <nav>
          <a className="home-link" href="#" onClick={handleSignOut}>Sign Out</a>
        </nav>
      </header>
      <div style={{ backgroundColor: DARK_THEME_COLORS.BACKGROUND_DARK }} className="home-main">
        <section  style={{ backgroundColor: DARK_THEME_COLORS.BACKGROUND_DARK }} className="featured">
          <h5>Create Photos</h5> 
          <div className="create-mode-scroller">
            <button 
            // className='create-mode-button' 
            className={`create-mode-button ${createMode === 'text' ? 'selected' : ''}`}
            onClick={handleCreateModeTextToImage}>Text to Image</button>
            <button 
            // className='create-mode-button' 
            className={`create-mode-button ${createMode === 'image' ? 'selected' : ''}`}

            onClick={handleCreateModeImageToImage}>Image to Image</button>
            <button 
            // className='create-mode-button' 
            className={`create-mode-button ${createMode === 'zillow' ? 'selected' : ''}`}

            onClick={handleCreateModeZillowToImages}>Address to Images</button>
          </div>
          {showProgressBar && 
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
            <ProgressBar progress={progress} />
          </div> }
          {createMode === 'image' && (
            <CreateImageEdit 
              onImageGeneratedSuccess={handleImageGeneratedSuccess}
            />
          )}
          {createMode === 'zillow' && (
            <AddressToImagesEdit 
            onImageGeneratedSuccess={handleImageGeneratedSuccess}  />
            // <div></div>
          )}
           {showImageGeneratedFailureModal && (
            <div className="image-upload-failure-modal">
              <div className="image-upload-failure-modal-content">
                <button className="image-upload-failure-modal-close" onClick={() => setShowImageGeneratedFailureModal(false)}>Close</button>
                <p className="image-upload-failure-modal-message">
                  {imageGeneratedFailureModalMessage}
                  {insufficientCredits && (
                    <a className="image-upload-failure-modal-link" href="https://enviromentai.com/options">Purchase Credits</a>
                  )}
                </p>
              </div>
            </div>
          )}
          {createMode === 'text' && (
            <CreateImageFromTextPrompt 
              onImageGeneratedSuccess={handleImageGeneratedSuccess}
              onImageGenerationFailure={handleImageGenerationFailure}
              onGenerateImage={onGenerateImage} // Pass the onGenerateImage function as prop
              />
          )}

          
          <div className="user-gen-images-section">
            <GeneratedImageGallery images={genImages} />
          </div>
        </section>
        <section  style={{ backgroundColor: DARK_THEME_COLORS.BACKGROUND_COLOR }} className="sidebar">
          {/* <h2>Details</h2> */}
          <PhotoHistory images={genImages} />
          { user ? 
            (<div>
              <div className="credits-summary">
                <p style={{color: DARK_THEME_COLORS.TEXT_COLOR}}>User: </p>
                <p style={{color: DARK_THEME_COLORS.TEXT_COLOR}}>{user.email}</p>
              </div>
              <div className="credits-summary">
                <p style={{color: DARK_THEME_COLORS.TEXT_COLOR}}>Credits Available: </p>
                <p style={{color: DARK_THEME_COLORS.TEXT_COLOR}}>{user.credits}</p>
              </div>
              <button  style={{color: DARK_THEME_COLORS.TEXT_COLOR}} className="buy-credits-home-button" onClick={openBuyCreditsModal}>Buy Credits</button>
            </div>)
            : ( <p>Loading or user not available</p>)
          }
            <div>
            </div>
            { showBuyCreditsModal && (
              <BuyCreditsModalDetails
                isOpen={isSubscriptionPopupOpen}
                onClose={() => setIsSubscriptionPopupOpen(false)}
                />
              )}
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            {proxyImages.map((design, index) => (
              <div key={design.id} className="home-sidebar-imgs">
                <img src={design.image_variant_url} />
              </div>
            ))}
          </div>
        </section>
      </div>
      <footer style={{ backgroundColor: DARK_THEME_COLORS.BACKGROUND_COLOR }}>
        <p>&copy; 2023 EnviromentAI. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default Home;