// import * as React from 'react' ;
// import {VARS} from '../../Constants.js';
// import GetSession from '../../GetSession'
import {API_ROOT} from '../../Constants.js';


const Put = async (path, payload, headers) => {
  if (!headers) { headers = { 'Accept': '*/*', 'Content-Type': 'application/json', 'Authorization': 'props' } }
  const PutJson = async(props) => {
    try {
      const response = await fetch(API_ROOT + '/api/v1/'+path, {
        method: 'PUT',
        headers: headers,
        body: payload,
      });
      const responseJson = await response.json();
      // console.log("Put Response: ",responseJson);
      const statusCode = response.status;
      return Promise.all([statusCode, responseJson])
    } catch (error){
      console.log("Error", error);
    }
  }
  return PutJson();
  
  };
export default Put;