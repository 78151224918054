import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './css/Subscriptions.css';

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from '../screens/CheckoutForm.js';
import Post from '../api/v1/Post.js';
// import {TEST} from '../Constants.js';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe(TEST.stripePublishableKey)

const SinglePaymentDetails = ({ isOpen, onClose, onSubscribe }) => {
  const [selectedPlan, setSelectedPlan] = useState('');

  // passing the client secret obtained from the server
//   const options = { clientSecret: TEST.stripeClientSecret };



// useEffect(() => {
//     console.log("selected plan has changed to: ", selectedPlan)
//     var session = localStorage.getItem("session_id")
//     if (session !== null) {
//         var jsonUserSession = JSON.stringify({"client_reference_id": session, 'product_name':'small'})
//         console.log("sending to stripe with session id: ",session);
//         Post('subscriptions/create_checkout_session', jsonUserSession)
//         .then((response) => {
//           window.location.href = response[1].url; // window.location.href = 'https://buy.stripe.com/test_cN214vcLU1Io3bW8ww';
//         })
//     } else {
//         // set by stripe customer detail / email serverside
//         console.log("no session, proceeding to stripe")
//         // crerate stripe session without user session. get from user checkout or payment email later. 
//         Post('subscriptions/create_checkout_session')
//         .then((response) => {
//           window.location.href = response[1].url; // window.location.href = 'https://buy.stripe.com/test_cN214vcLU1Io3bW8ww';
//         // console.log("session response is: ",response[1].url);
//         })
//     }
//     return () => {
//       // Cleanup logic (if needed)
//     };
//   }, [selectedPlan]);
  
  const handleSubscribe = () => {
    onSubscribe(selectedPlan);
    onClose();
  };

  const handleBackdropClick = (e) => {
    if (e.target.classList.contains('subscriptionOverlay')) {
      onClose();
    }
  };

  //  (react) click subscribe button > (ruby) create checkout session, return session id > (react) redirect to checkout url
  const handleSelectedPlan = (size) => {
    // setSelectedPlan()
    var session = localStorage.getItem("session_id")
    // if does not have session
    if (session !== null) {
    //   var jsonUserSession = JSON.stringify({"client_reference_id": session})
      var jsonUserSession = JSON.stringify({"client_reference_id": session, 'product_name':size})
      // console.log("sending to stripe with session id: ",session);
      const headerParams = {
        'Accept': '*/*',
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Connection': 'keep-alive',
        'Authorization': `Bearer ${session}` // Include your actual token
      };
      Post('subscriptions/create_checkout_session', jsonUserSession, headerParams)
      .then((response) => {
        window.location.href = response[1].url; // window.location.href = 'https://buy.stripe.com/test_cN214vcLU1Io3bW8ww';
      })
    // if has session
    } else {
      // set by stripe customer detail / email serverside
      var jsonUserSession = JSON.stringify({'product_name':size})
      Post('subscriptions/create_checkout_session', jsonUserSession);
      // .then((response) => {
        // var urlString = 
        // console.log("session response is: ",response[1].url);
        // window.location.href = 
        // window.location.href = 'https://buy.stripe.com/test_cN214vcLU1Io3bW8ww';
      // })
    }
    // const stripe = Stripe('pk_test_51Nds8hEQV7VZRFF8uJ5kxSrbfBw5AUt7fJztw7eJInp8BhFPjcYcf0DAR870d2vEd5uKE1eyN82rFeG26M0EyOFa00MnDktmzE');

  }

  if (!isOpen) {
    return null;
  }

  return (
    // <body>
    <div className="modal-overlay" onClick={handleBackdropClick}>
      <div className="modal-container">
      {/* <h2>Choose a Subscription Plan</h2> */}
      {/* <div className="plans-container"> */}
        <div className="plan">
          {/* <h2>Still in Testing</h2> */}
          <h3 className="plan-title">Small</h3>
          <p className="plan-price">$20</p>
          <button className="plan-button" onClick={() => handleSelectedPlan('small')}>Select</button>
          {/* <button className="plan-button" onClick={() => setSelectedPlan('small')}>Select</button> */}
          <p className="plan-info">✓ Generate 256 AI photos</p>
          <p className="plan-info">✓ Copy any photo</p>
          <p className="plan-info">✓ AI photo editor</p>
          <p className="plan-info">✓ Write your own descriptions</p>
        </div>
        {/* <Elements stripe={stripePromise}>
          <CheckoutForm />
        </Elements> */}

        {/* <Elements stripe={stripePromise} options={options}>
          <CheckoutForm />
        </Elements> */}
        <div className="plan">
          {/* <h2>Still in Testing</h2> */}
          <h3 className="plan-title">Medium</h3>
          <p className="plan-price">$50</p>
          {/* <button className="plan-button" onClick={() => setSelectedPlan('premium')}>Select</button> */}
          <button className="plan-button" onClick={() => handleSelectedPlan('medium')}>Select</button>
          <p className="plan-info">✓ Small plan features included</p>
          <p className="plan-info">✓ Generate 800 AI photos</p>
          {/* <p className="plan-info">✓ Beta access to new features</p>
          <p className="plan-info">✓ Priority image creation</p> */}
        </div>
        <div className="plan">
          {/* <h2>Still in Testing</h2> */}
          <h3 className="plan-title">Large</h3>
          <p className="plan-price">$100</p>
          <button className="plan-button" onClick={() => handleSelectedPlan('large')}>Select</button>
          <p className="plan-info">✓ Medium plan features included</p>
          <p className="plan-info">✓ Generate 1600 AI photos</p>
          {/* <p className="plan-info">✓ Available with increased demand</p>
          <p className="plan-info">✓ Image reports and metrics</p> */}
        </div>
        {/* <div className="modal-buttons">
          <button className="subscribe-button" onClick={handleSubscribe}>Subscribe</button>
          <button className="subscribe-button" onClick={onClose}>Close</button>
        </div> */}
      </div>
    </div>
    // </body>
  );
};

export default SinglePaymentDetails;
