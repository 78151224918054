// GeneratedImageGallery.js
import React, { useState, useEffect } from 'react';
import './css/GeneratedImageGallery.css'; // Import the existing CSS file for authentication modal styling
import Get from '../../api/v1/Get';
import { API_ROOT } from '../../Constants.js';

const GeneratedImageGallery = ({ images }) => {
  // console.log("gen images: ",images)
  // console.log("first gen image: ",images[0])
  var session = localStorage.getItem('session_id');

  const [downloadHover, setDownloadHover] = useState(false);

  // const handleDownload = (url) => {
  const handleDownload = (id, url, filename) => {
  // Use fetch to download the image data
  // Get('/api/v1/generatedimgs/' + id + '/download')
  // var getRequest = '/api/v1/generatedimgs/' + id + '/download'
  // const fullUrl = url.includes('http') ? url : API_ROOT + '/api/v1/' + url;
    // Set up fetch options including the authentication header
    // console.log("ID: ", id)
    var session = localStorage.getItem('session_id');

    const requestOptions = {
      headers: {
        Authorization: `Bearer ${session}`, // Include your authentication token here
      },
    };
  
  // var imageUrl = 'http://localhost:3000/api/v1/generatedimgs/'+id+'/download'
  var url = 'generatedimgs/'+id+'/download'
  const imageUrl = url.includes('http') ? url : API_ROOT + '/api/v1/' + url;

  // console.log("requesting url:... ", imageUrl)
  fetch(imageUrl, requestOptions)
  // fetch(url)
    .then((response) => {
    // console.log('Fetch response:', response);
    return response.blob() // Convert the response to a Blob
    }).then((blob) => {
      // Create a temporary anchor element
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob); // Create a local URL for the Blob
      link.download = filename; // Set the download attribute with the desired file name
      link.click(); // Simulate a click event to trigger the download
    })
    .catch((error) => {
      console.error('Error downloading image:', error);
    });
  };

  return (
    <div className="image-gallery">
      {images.map((image, index) => (
        <div key={index} className="gallery-item">
          <img src={image[0].url} alt={`Image ${index + 1}`} />
          <div className="download-button-container">
            <button
              className="download-button"
              onMouseEnter={() => setDownloadHover(true)}
              onMouseLeave={() => setDownloadHover(false)}
              // onClick={() => handleDownload(image[0].id, image[0].url)}
              onClick={() => handleDownload(image[0].id, image[0].url, image[0].filename)}

            >
              <span className={`download-icon ${downloadHover ? 'highlight' : ''}`}>&#x2B07;</span>
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default GeneratedImageGallery;