import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import Post from '../../api/v1/Post.js';
import './css/BuyCreditsModalDetails.css';
import { DARK_THEME_COLORS } from '../../Constants';


const BuyCreditsModalDetails = ({ isOpen, onClose }) => {
  const [selectedPlan, setSelectedPlan] = useState('');

  const handleBackdropClick = (e) => {
    if (e.target.classList.contains('subscriptionOverlay')) {
      onClose();
    }
  };

  //  (react) click subscribe button > (ruby) create checkout session, return session id > (react) redirect to checkout url
  const handleSelectedPlan = (size) => {
    var session = localStorage.getItem("session_id")
    if (session !== null) {
      var jsonUserSession = JSON.stringify({"client_reference_id": session, 'product_name':size})
      console.log("sending to stripe with session id: ",session);
      const headerParams = {
        'Accept': '*/*',
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Connection': 'keep-alive',
        'Authorization': `Bearer ${session}` // Include your actual token
      };
      Post('subscriptions/create_checkout_session', jsonUserSession, headerParams)
      .then((response) => {
        window.location.href = response[1].url; // window.location.href = 'https://buy.stripe.com/test_cN214vcLU1Io3bW8ww';
      })
    } else {     // if not not have session. this might be broken?
      // set by stripe customer detail / email serverside
      var jsonUserSession = JSON.stringify({'product_name':size})
      Post('subscriptions/create_checkout_session', jsonUserSession)
      .then((response) => {
        console.log("session response is: ",response[1].url);
      })
    }
  }

  if (!isOpen) {
    return null;
  }

  return (
    <div className="buy-credits-modal-overlay" onClick={handleBackdropClick}>
      <div style={{ backgroundColor: DARK_THEME_COLORS.BACKGROUND_COLOR }} className="buy-credits-modal-container">
        <button className="buy-credits-close-button" onClick={onClose}>X</button>
        <div className="buy-credits-plan">
          <div class="buy-credits-plan-contents"> 
            <h3 style={{color: DARK_THEME_COLORS.TEXT_COLOR}} className="buy-credits-plan-title">Small</h3>
            <p style={{color: DARK_THEME_COLORS.TEXT_COLOR}} className="buy-credits-plan-price">$20</p>
            <button style={{color: DARK_THEME_COLORS.TEXT_COLOR}} className="buy-credits-plan-button" onClick={() => handleSelectedPlan('small')}>Select</button>
            <p style={{color: DARK_THEME_COLORS.TEXT_COLOR}} className="buy-credits-plan-info">✓ Generate 256 AI photos</p>
            <p style={{color: DARK_THEME_COLORS.TEXT_COLOR}} className="buy-credits-plan-info">✓ Copy any photo</p>
            <p style={{color: DARK_THEME_COLORS.TEXT_COLOR}} className="buy-credits-plan-info">✓ AI photo editor</p>
            <p style={{color: DARK_THEME_COLORS.TEXT_COLOR}} className="buy-credits-plan-info">✓ Write your own descriptions</p>
          </div>
        </div>
        <div className="buy-credits-plan">
          <div class="buy-credits-plan-contents"> 
            <h3 style={{color: DARK_THEME_COLORS.TEXT_COLOR}} className="buy-credits-plan-title">Medium</h3>
            <p style={{color: DARK_THEME_COLORS.TEXT_COLOR}} className="buy-credits-plan-price">$50</p>
            <button style={{color: DARK_THEME_COLORS.TEXT_COLOR}} className="buy-credits-plan-button" onClick={() => handleSelectedPlan('medium')}>Select</button>
            <p style={{color: DARK_THEME_COLORS.TEXT_COLOR}} className="buy-credits-plan-info">✓ Small plan features included</p>
            <p style={{color: DARK_THEME_COLORS.TEXT_COLOR}} className="buy-credits-plan-info">✓ Generate 800 AI photos</p>
          </div>
        </div>
        <div className="buy-credits-plan">
          <div class="buy-credits-plan-contents"> 
            <h3 style={{color: DARK_THEME_COLORS.TEXT_COLOR}} className="buy-credits-plan-title">Large</h3>
            <p style={{color: DARK_THEME_COLORS.TEXT_COLOR}} className="buy-credits-plan-price">$100</p>
            <button style={{color: DARK_THEME_COLORS.TEXT_COLOR}} className="buy-credits-plan-button" onClick={() => handleSelectedPlan('large')}>Select</button>
            <p style={{color: DARK_THEME_COLORS.TEXT_COLOR}} className="buy-credits-plan-info">✓ Medium plan features included</p>
            <p style={{color: DARK_THEME_COLORS.TEXT_COLOR}} className="buy-credits-plan-info">✓ Generate 1600 AI photos</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyCreditsModalDetails;
