import React, { useState, useRef } from 'react';
import './css/CreateImageEdit.css';
import Post from '../../api/v1/Post';
import PhotoMaskEditor from './PhotoMaskEditor';

const CreateImageEdit = ({ onImageGeneratedSuccess }) => {
  const [uploadedImage, setUploadedImage] = useState(null);
  const [textPromptValue, setTextPromptValue] = useState('');
  const [photoMask, setPhotoMask] = useState(null);
  const [uploadedImageFile, setUploadedImageFile] = useState(null);
  const [selectedSize, setSelectedSize] = useState('256x256');

  const fileInputRef = useRef(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setUploadedImage(URL.createObjectURL(file));
    const pngFile = new File([file], 'uploaded_image.png', { type: 'image/png' });
    setUploadedImageFile(pngFile);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setUploadedImage(URL.createObjectURL(file));
    const pngFile = new File([file], 'uploaded_image.png', { type: 'image/png' });
    setUploadedImageFile(pngFile);
  };

  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  const handleTextPromptChange = (event) => {
    setTextPromptValue(event.target.value);
  };

  const handleSizeButtonClick = (size) => {
    setSelectedSize(size);
  };

  const handleGenerateImage = () => {
    const formData = new FormData();
    formData.append('image', uploadedImageFile);
    formData.append('mask', photoMask);
    formData.append('prompt', textPromptValue);
    formData.append('size', selectedSize);

    var session = localStorage.getItem('session_id');
    var headerParams = {
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Connection: 'keep-alive',
      Authorization: `Bearer ${String(session)}`,
    };
    Post('openai/gen_image_edit', formData, headerParams).then((result) => {
      // console.log("this is the result: ", result);
      onImageGeneratedSuccess();
    });
  };

  return (
    <div>
      {uploadedImage && (
        <PhotoMaskEditor image={uploadedImage} onPhotoMaskSave={setPhotoMask} />
      )}
      <div className="image-upload-container">
        <div className="size-container">
          <h4>Size: </h4>
          <div className="size-buttons-container">
            {['256x256', '512x512', '1024x1024'].map((size) => (
              <button
                key={size}
                className={`size-button ${selectedSize === size ? 'selected' : ''}`}
                onClick={() => handleSizeButtonClick(size)}
              >
                {size}
              </button>
            ))}
          </div>
        </div>
        {!uploadedImage && (
        <div
          className="image-upload"
          onDragOver={(event) => event.preventDefault()}
          onDrop={handleDrop}
          onClick={handleBrowseClick}
        >
          <div className="drag-drop-area">
            <p>Click or drag and drop your landscape photo here</p>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              style={{ display: 'none' }}
              ref={fileInputRef}
            />
          </div>
          <button className="upload-button">Upload Landscape Photo</button>
        </div>
        )}
        <div className="create-photos-text">
          <textarea
            value={textPromptValue}
            onChange={handleTextPromptChange}
            placeholder="Enter text here"
            className="text-input"
            style={{
              width: '90%',
              height: '80px',
              resize: 'none',
              borderRadius: '5px',
              padding: '10px',
              margin: 'auto',
            }}
          />
          <button className="generate-image-via-text-button" onClick={handleGenerateImage}>
            Generate Image
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateImageEdit;
