// import React from 'react';
import React, { useState, useEffect } from 'react';
import {useSearchParams, useNavigate} from "react-router-dom";

import './../../styles.css';
import EmailPopup from './../../EmailPopup'; // Make sure to import the Popup component
import PostUser from './../../js/api/v1/PostUser';
import PostEmail from './../../js/api/v1/PostEmail';
import SubscriptionPopup from './../../SubscriptionPopup';
import Subscriptions from './../../js/subViews/Subscriptions';
import ImageUpload from './../../js/ImageUpload'; // Import the ImageUpload component
import Modal from 'react-modal';
import LandscapeGallery from './../../LandscapeGallery'; // Import the LandscapeGallery component
import './../../LandscapeGallery.css'; // Import the CSS file for LandscapeGallery
import testShot from './../../images/testShot.jpg';
import pineWatt from './../../images/pineWatt.jpg';
import MainFeed from './../../js/MainFeed'; // Import the MainFeed component
import AestheticImageEditor from './../../js/api/v1/openai/AestheticImageEditor';
import OpenAITest from './../../js/api/v1/openai/OpenAITest';
import ImageReturnTest from './../../js/api/v1/openai/ImageReturnTest';

// Utils
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Stripe from 'stripe';

// Shows payment plans, Page to autonavigate to from email with session id as query parameter, 
function Plans() {
  const [email, setEmail] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [showCreatedUserPopup, setShowCreatedUserPopup] = useState(false);
  const [isSubscriptionPopupOpen, setIsSubscriptionPopupOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [userSession, setUserSession] = useState('');


  useEffect(() => {
    // This block will run once when the component mounts
    if (!userSession) {
      const sessionToken = searchParams.get("session_id")
      // Perform your one-time initialization here
      if (sessionToken) {
        localStorage.setItem("userSession", sessionToken)
        // setUserSession(sessionToken);
        // console.log('User session has been set');
      } else {
        console.log('Error: Session not found'); 
        // navigate("/");
        // redirect back to home 
      }
    }
    return () => {
    };
  }, [1]); 


  useEffect(() => {
    // This block will run once when the component mounts
    if (!isSubscriptionPopupOpen) {
      // Perform your one-time initialization here
      console.log('Component has been initialized');
      // Set the variable to indicate that initialization has occurred
      setIsSubscriptionPopupOpen(true);
    }
    // If you have any cleanup logic for the effect, you can return a function
    // Note: Since we use an empty dependency array, this cleanup will only run on unmount
    return () => {
      // Cleanup logic (if needed)
    };
  }, [isSubscriptionPopupOpen]); // Add 'initialized' to the dependency array to satisfy the exhaustive-deps rule

  // const stripe = Stripe('pk_test_51Nds8hEQV7VZRFF8uJ5kxSrbfBw5AUt7fJztw7eJInp8BhFPjcYcf0DAR870d2vEd5uKE1eyN82rFeG26M0EyOFa00MnDktmzE');
  // Collect payment details and generate a token
//   const { token, error } = await stripe.createToken(cardElement);
//   stripe.redirectToCheckout({
    // sessionId: 'your_stripe_checkout_session_id',
//   });


  // const navAuthenticated = () => { return navigation.navigate('Authenticated'); }

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubscribe = (selectedPlan) => {
    // Send the selected plan to the backend for processing
    // You would need to implement API calls to your Ruby backend here
    // console.log('Subscribing to', selectedPlan);
  };

  const handleSubmitEmail = (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    // Perform any action with the email, e.g., submit to a server or display it
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(email)) {
      // Perform any action with the valid email, e.g., submit to a server
      // console.log("valid email: ", email);
      PostEmail(JSON.stringify({"email": email, "password": '012345678910'}))
      .then((response) => {
        if (response.status) {
          return Promise.resolve(setShowCreatedUserPopup(true));
        }
      })
      // send email verification signup call to api
    } else {
      // console.log("invalid email: ",email);
      // Show the popup if the email is invalid
      setShowPopup(true);
    }
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault(); // Prevent default form submission behavior
  //   // Perform any action with the email, e.g., submit to a server or display it
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   if (emailRegex.test(email)) {
  //     // Perform any action with the valid email, e.g., submit to a server
  //     // console.log("valid email: ", email);
  //     PostUser(JSON.stringify({"email": email, "subscribed": false}))
  //     .then((response) => {
  //       if (response.status) {
  //         return Promise.resolve(setShowCreatedUserPopup(true));
  //       }
  //     })
  //     // send email verification signup call to api
  //   } else {
  //     // console.log("invalid email: ",email);
  //     // Show the popup if the email is invalid
  //     setShowPopup(true);
  //   }
  // };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleShowCreatedUserPopup = () => {
    setShowCreatedUserPopup(false);
  }

  const [selectedDesign, setSelectedDesign] = useState(null);

  const designs = [
    { name: 'Modern Garden', image: testShot }, //pineWatt
    { name: 'Traditional Landscape', image: testShot },
    { name: 'Zen Retreat', image: testShot }, //'zen-retreat.jpg'
    { name: 'Cottage Garden', image: testShot },
    { name: 'Desert Oasis', image: testShot },
    { name: 'Tropical Paradise', image: testShot },
    { name: 'English Country Garden', image: testShot },
    { name: 'Mediterranean Courtyard', image: testShot },
    { name: 'Asian Zen Garden', image: testShot },
    { name: 'Coastal Escape', image: testShot },
    { name: 'Urban Rooftop Garden', image: testShot },
    { name: 'Wildflower Meadow', image: testShot },
    { name: 'Formal Courtyard', image: testShot },
    { name: 'Xeriscape Garden', image: testShot },
    // Add more design options here
  ];

  const handleSelectDesign = (design) => {
    setSelectedDesign(design);
  };

  const handleImageReturn = () => {
    return ImageReturnTest();
  };

  const handleOpenAITest = () => {
    // console.log("test handle open ai");
    return OpenAITest(); // Call the imported function when the button is clicked
  };

  // const navigateAuthenticated = () => {
  //   // console.log("test handle open ai");
  //   return Authenticated(); // Call the imported function when the button is clicked
  // };

  return (
    <div className="homepage">
      <header>
        <h1>EnviromentAI</h1>
        <nav>
          <a href="#">Home</a>
          {/* <a href="#">Articles</a> */}
          {/* <a href="#">Landscapes</a> */}
          {/* <Link to="/authenticated">Authenticated</Link> */}
        </nav>
      </header>
      <main>
        <div style={{display: 'flex', width: '100%'}}>
        <div style={{ flex: 1, width:'50%', flexDirection:'column'}}>
          <div style={{ flex: 1}}>
            <h2>Create Beautiful AI landscapes without a camera</h2>
            {/* <button onClick={handleOpenAITest}>Call OpenAITest</button> */}
          </div>
          <div style={{ flex: 1}}>
            <h5>Save money and use AI instead of hiring an expensive landscaper. View what your home would look like in countless styles.</h5>
          </div>
        </div>
        {/* <div>
          <form>
            <label>
              <input type="text" placeholder="type your email..." value={email} onChange={handleEmailChange}/>
            </label> 
            <button onClick={handleSubmitEmail}>Trigger email send</button>
          </form>
        </div> */}
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width:'50%'}}>
            <form style={{flex:1}}>
              <label style={{flex:1}}>
                <input style={{ boxSizing:'border-box', width: '100%',
                    padding: '10px',
                    borderRadius: '5px',
                  }}
                  type="text" name="emailInput" placeholder="type your email..." 
                  value={email}
                  onChange={handleEmailChange} />
              </label>
              <div className="email-button" style={{boxSizing:'border-box',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                borderRadius: '5px',
                color: 'rgb(51, 51, 51)',
                backgroundColor: 'rgb(245, 245, 245)',
                marginTop:15}} >

                <button className="gradient-button hover-email-button" 
                style={{
                  width: '100%',
                  padding: '10px',
                  textDecoration: 'none',
                  color: '#333',
                  fontSize: '16px',
                  cursor: 'pointer',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  borderRadius: '8px'}} 
                  onClick={handleSubmitEmail}>Start using EnviromentAI</button>
                {showPopup && (
                  <EmailPopup message="Your email seems invalid, is there a typo?" onClose={handleClosePopup} />
                )}
                {showCreatedUserPopup && (
                  <EmailPopup message="User created. We will update you when services are available" onClose={handleShowCreatedUserPopup} />
                )}
              </div>
              <div  style={{textAlign: 'center'}}>
                <text style={{textAlign: 'right'}}>If you already have an account, we'll log you in</text>
              </div>
            </form>
          </div>
        </div>
        
        <p>At EnviromentAI, we're passionate about helping you grow healthy and beautiful plants. Our team of experts has experience in gardening and plant care, and we're excited to share our knowledge with you.</p>
        <div style={{
          display: 'flex',
          margin: 'auto',
          width: 400,
          flexWrap: 'wrap',
        }}>
          
        </div>
        {/* <AestheticImageEditor /> */}
        {/* <button onClick={handleImageReturn}>Test returnImage</button> */}
        {/* <button onClick={navigateAuthenticated}>Navigate Authenticated</button> */}
        {/* <div>< ImageUpload /></div> */}
        <MainFeed />

        <div className="app">
          <h1>Create AI Landscapes</h1>
          <button onClick={() => setIsSubscriptionPopupOpen(true)}>Subscriptions</button>
          {isSubscriptionPopupOpen && (
            <Subscriptions
            isOpen={isSubscriptionPopupOpen}
            onClose={() => setIsSubscriptionPopupOpen(false)}
            onSubscribe={handleSubscribe}
          />
          )}
        </div>

        <div>
          {/* <h1>Landscape Design Options</h1> */}
          {/* <LandscapeGallery designs={designs} onSelectDesign={handleSelectDesign} style={{ '--designs-length': designs.length, '--animation-duration': `${designs.length * 5}s` }} /> */}
        </div>
      </main>
      <footer>
        <p>&copy; 2023 EnviromentAI. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default Plans;