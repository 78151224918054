// LoginForm.js
import React, { useState, useEffect } from 'react';
import './css/LoginForm.css';
import PostSignIn from '../api/v1/ruby/PostSignIn'
import PostSignUp from '../api/v1/ruby/PostSignUp'
import Post from '../api/v1/Post'
import Get from '../api/v1/Get'
import { useNavigate } from 'react-router-dom'

import EmailPopup from '../../EmailPopup'; // Make sure to import the Popup component


// import Get from '../api/v1/Get'

const LoginForm = ({ onClose, onLoginSuccess, onSignupSuccess, initialEmail }) => {
    const [email, setEmail] = useState(initialEmail || '');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [emailExists, setEmailExists] = useState(false); // determines if signup or login text is used
    // const navigate = useNavigate()

    const [showCreatedUserPopup, setShowCreatedUserPopup] = useState(false);
    const handleShowCreatedUserPopup = () => {  setShowCreatedUserPopup(false);   }
//     console.log("loginForm: ")


    // check for user session, if none, request login
    useEffect(() => {
        var emailJson =  JSON.stringify({ user: {email: email} })
        Post('users/email_exists', emailJson)
        .then((result) => {
            // console.log("show user exists result: ",result)
            if(result[0] < 300 && result[1].exists == true) {
                console.log("result is ok")
                // setUserExists(data.exists)
                return setEmailExists(true)
            } 
        })

        // return () => {
        // };
      }, [0]); 


//   const handleSubscribe = (selectedPlan) => {
//     // Send the selected plan to the backend for processing
//     // You would need to implement API calls to your Ruby backend here
//     // console.log('Subscribing to', selectedPlan);
//   };

  const handleLogins = (e) => {    e.preventDefault(); 
  };

  // handles both login and signup dependent on if a user is existinng. 
  // if signup, creates and sends email verification, which then directs to /planns with sessino id
  // if login, logs in and redirects to /home
  const handleLogin = (e) => {
    try {
        e.preventDefault();
        // 012345678910
        // console.log("start handle signup or login")
        var credentialsJson = JSON.stringify({ "email":email, "password":password })
        PostSignUp(credentialsJson)
        .then((response) => {
            if(response.error) {
                if(response.error == 'Email is already taken') {
                    // console.log("move to sign in ")
                    PostSignIn('sign_in', credentialsJson)
                    .then((response) => {
                        // console.log("got sign in results: ",response)
                        if(!response[2]) {
                            //says if no error message
                            // const data = response.json();
                            // response 0 is session
                            // console.log("results: ",response[0])
                            // console.log("headers: ",response[1].get('x-session-token'))
            
                            // Save session to local storage
                            localStorage.setItem('session_id', JSON.stringify(response[1].get('x-session-token')));
                            localStorage.setItem("user_id", JSON.stringify(response[0].user_id))
                            localStorage.setItem("credits", JSON.stringify(response[0].credits))
                            onLoginSuccess();   // navigate('/home')
                            onClose();          // Close the popup
                        } else {
                            // Handle invalid password or other errors
                            setErrorMessage('Invalid password.');        
                        }
                    })
                } else {
                    // console.log("has error: ", response.error) // handle errors other than email already taken
                }
            } else {
                // console.log("no sign up error")
                onSignupSuccess() // show created user popup in parent element
                onClose();   
                // add success logic (send email verification and redirect to /plans)
            }
        })
    } catch (error) {
        console.error('Error during login:', error.message);
    }
  };

  return (
    <div className="login-modal-overlay">
      <div className="login-modal-content">
        {emailExists ? ( <h2>Login</h2> ) : ( <h2>Signup</h2> ) }
        <label>
            {emailExists ? 
            ( 
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Type your password..."
              />
            ) : 
            ( 
                <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Set your password..."
              />
            ) }
          
        </label>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <button onClick={handleLogin}>Submit</button>
      </div>
    </div>
  );
};

export default LoginForm;


        // Post('sign_in', loginJson)
        // const response = await fetch('http://localhost:3000/api/v1/sign_in', {
        //     method: 'POST',
        //     headers: {
        //       'Content-Type': 'application/json',
        //     },
        //     body: loginJson,
        //   });
        //   if (response.ok) {
        //     const data = await response.json(); 
        //     console.log("show response: ",data)
        // }


        // .then((response) => {
        //     return PostSignIn('sign_in', credentialsJson)
        // })
        // .then((response) => {
        //     console.log("got results: ",response)
        //     //says if no error message
        //     if(!response[2]) {
        //         // const data = response.json();
        //         // response 0 is session
        //         console.log("results: ",response[0])
        //         console.log("headers: ",response[1].get('x-session-token'))

        //         // Save session to local storage
        //         localStorage.setItem('session_id', JSON.stringify(response[1].get('x-session-token')));
        //         localStorage.setItem("user_id", JSON.stringify(response[0].user_id))
        //         // navigate('/home')
        //         onLoginSuccess();
        //         // Close the popup
        //         onClose();

        //     } else {
        //         console.log("bad response handle login")
        //         // Handle invalid password or other errors
        //         setErrorMessage('Invalid password.');
        //       }
        // })