import React from 'react';

import './css/home.css'
import '../../styles.css';
import './css/Status.css'


// Function to get status color based on status
const getStatusColor = (status) => {
  switch (status) {
    case 'Operational':
      return 'green';
    case 'Degraded Performance':
      return 'orange';
    case 'Major Outage':
      return 'red';
    default:
      return 'gray';
  }
};

// Component for Status Over Time
const StatusOverTime = () => {
  // Sample data for status over time (replace with actual data)
  const statusData = [
    { date: '2024-02-01', status: 'Operational' },
    { date: '2024-02-02', status: 'Operational' },
    { date: '2024-02-03', status: 'Degraded Performance' },
    { date: '2024-02-04', status: 'Operational' },
    { date: '2024-02-05', status: 'Major Outage' },
  ];

  return (
    <div>
      <h2>Status Over Time</h2>
      <div className="status-over-time">
        {statusData.map((entry, index) => (
          <div key={index} className="status-bar" style={{ backgroundColor: getStatusColor(entry.status) }}>
            <p>{entry.date}</p>
            <p>{entry.status}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

// Component for Previous Incidents
const PreviousIncidents = () => {
  // Sample data for previous incidents (replace with actual data)
  const previousIncidents = [
    { date: '2024-01-28', description: 'DNS Resolution Issues' },
    { date: '2024-01-15', description: 'Server Downtime' },
    { date: '2024-01-05', description: 'Network Outage' },
  ];

  return (
    <div>
      <h2>Previous Incidents</h2>
      <ul className="previous-incidents">
        {previousIncidents.map((incident, index) => (
          <li key={index}>
            <p>{incident.date}</p>
            <p>{incident.description}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

// Main Page Component
const Status = () => {
  return (
    <div className="home">
        <header>
            <h1>EnviromentAI</h1>
            <nav>
                {/* <a className="home-link" href="#" onClick={handleSignOut}>Sign Out</a> */}
            </nav>
        </header>
      {/* <div className="home-main"> */}
        <div className="status-page">
            <p className="status-header">Current status</p>
            <div>
                <div className="current-status-header">
                    <p>Backend: </p>
                    <p className="availability-status"> Available</p>
                </div>
                <div className="current-status-header">
                    <p>Frontend: </p>
                    <p className="availability-status"> Available</p>
                </div>
            </div>
        {/* <StatusOverTime />
        <PreviousIncidents /> */}
        </div>
        {/* </div> */}
    </div>

  );
};

export default Status;
