import React, { useState } from 'react';
import Modal from 'react-modal';

// API Calls
import OpenAITest from '../api/v1/openai/OpenAITest';
import ImageReturnTest from '../api/v1/openai/ImageReturnTest';
import PostUser from '../api/v1/PostUser';
import Get from '../api/v1/Get'

//  subViews
import AestheticUploadButton from '../subViews/AestheticUploadButton';
import AestheticUpload from '../subViews/AestheticUpload';
import ProxyImgButtonGenerator from '../subViews/ProxyImgButtonGenerator'; // Admin - Button to create and store proxy images
import ProxyImage from './subScreens/ProxyImage.js'; // Admin - Button to create and store proxy images

import MainFeed from '../MainFeed'; // Import the MainFeed component
import AestheticImageEditor from '../api/v1/openai/AestheticImageEditor';
import LandscapeGallery from '../../LandscapeGallery'; // Import the LandscapeGallery component
import SubscriptionPopup from '../../SubscriptionPopup';
import ImageUpload from '../ImageUpload'; // Import the ImageUpload component
import EmailPopup from '../../EmailPopup'; // Make sure to import the Popup component
// CSS
import '../../styles.css'
import '../../LandscapeGallery.css'; // Import the CSS file for LandscapeGallery

// Images
import testShot from '../../images/testShot.jpg';
import pineWatt from '../../images/pineWatt.jpg';

const designs = [
  { name: 'Modern Garden', image: testShot }, //pineWatt
  { name: 'Traditional Landscape', image: testShot },
  { name: 'Zen Retreat', image: testShot }, //'zen-retreat.jpg'
  { name: 'Cottage Garden', image: testShot },
  { name: 'Desert Oasis', image: testShot },
  { name: 'Tropical Paradise', image: testShot },
  { name: 'English Country Garden', image: testShot },
  { name: 'Mediterranean Courtyard', image: testShot },
  { name: 'Asian Zen Garden', image: testShot },
  { name: 'Coastal Escape', image: testShot },
  { name: 'Urban Rooftop Garden', image: testShot },
  { name: 'Wildflower Meadow', image: testShot },
  { name: 'Formal Courtyard', image: testShot },
  { name: 'Xeriscape Garden', image: testShot },
]; 

function Authenticated() {

  const [proxyImages, getProxyImages] = useState([])
  const [selectedDesign, setSelectedDesign] = useState(null);

  const handleSelectDesign = (design) => {
    setSelectedDesign(design);
  };

  const handleImageReturn = () => {
    return ImageReturnTest();
  };

  const handleOpenAITest = () => {
    // console.log("test handle open ai");
    return OpenAITest(); // Call the imported function when the button is clicked
  };

  const handleImageGeneratedSuccess = () => {
    console.log("handle image generated success"); 
    // get all or new images from user
    var session = localStorage.getItem('session_id');
    Get('proxyimgs',`Bearer ${String(session)}`)
    .then((result) => {
      console.log("proxy user images result: ",result);
      // store user images in state variable
      // if (result[0] < 300) {
      //   getProxyImages(result[1])
      //   // gen images will be displayed on this home page
      // } else {console.log("bad result for get images: ",result)}
    })
  };
  
  return (
    <div className="homepage">
      <header>
        <h1>EnvironmentAI</h1>
        <nav>
          <a href="#">Home</a>
          <a href="#">Landscapes</a>
        </nav>
      </header>
      <main>
        <div style={{display: 'flex', width: '100%'}}>
        <div style={{ flex: 1, width:'50%', flexDirection:'column'}}>
          <div style={{ flex: 1}}>
            <h2>What style best suits you?</h2>
            {/* <button onClick={handleOpenAITest}>Call OpenAITest</button> */}
          </div>
        </div>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width:'50%'}}>
          </div>
        </div>
        
        <p>At EnvironmentAI, we're passionate about helping you grow healthy and beautiful plants. Our team of experts has years of experience in gardening and plant care, and we're excited to share our knowledge with you.</p>
        <div style={{
          display: 'flex',
          margin: 'auto',
          width: 400,
          flexWrap: 'wrap',
        }}>
          
        </div>

        {/* <AestheticUpload /> */}
        {/* <ProxyImgButtonGenerator /> */}
        <ProxyImage  onImageGeneratedSuccess={handleImageGeneratedSuccess} />

        <MainFeed />
        <div className="app">
        </div>
        {/* <div>
          <LandscapeGallery designs={designs} onSelectDesign={handleSelectDesign} style={{ '--designs-length': designs.length, '--animation-duration': `${designs.length * 5}s` }} />
        </div> */}
      </main>
      <footer>
        <p>&copy; 2023 EnviromentAI. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default Authenticated;